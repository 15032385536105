import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../wrappers/UserContext'
import { Redirect } from 'react-router-dom'
import { checkRole, getRoles } from '../../../utils/user/permissions'
import urls from '../../../utils/constants/urls'

const RestrictedAccess = ({ allowedRoles, children }) => {
    const { client } = useContext(UserContext)
    const [hasAccess, setHasAccess] = useState(true)
    
    useEffect(() => {
        const checkClientAccess = async allowedRoles => {
            await client.getUser().then(user => {
                const roles = getRoles(user)
                console.log('roles:', roles)
                console.log('allowedRoles:', allowedRoles)
                if (allowedRoles.some(role => checkRole(role, roles))) {
                    setHasAccess(true)
                } else {
                    setHasAccess(false)
                }
            })
        }
        checkClientAccess(allowedRoles)
    }, [client, allowedRoles])
    
    if (hasAccess) {
        return children
    } else {
        return <Redirect to={urls.login} />
    }
}

export default RestrictedAccess