import React, { useContext } from 'react'
import { hasAccessToAdmin } from '../../../utils/user/permissions'
import { UserContext } from '../../wrappers/UserContext'
import withConfig from '../../wrappers/withConfig'

const UserDropdown = withConfig(({ config, roles }) => {
    const { CONFIGURED_URLS } = config
    const { client } = useContext(UserContext)
    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link userDropdownLink">Account</a>
            <div className="navbar-dropdown is-right">
                {hasAccessToAdmin(roles) ? (
                    <div className="navbar-item userDropdownLink">
                        <a href={CONFIGURED_URLS.manageUsers} target="_blank">
                            Manage Users
                        </a>
                    </div>
                ) : null}
                <div className="navbar-item userDropdownLink">
                    <a href={CONFIGURED_URLS.changePassword} target="_blank">
                        Change Password
                    </a>
                </div>
                <div className="navbar-item userDropdownLink">
                    <a
                        href="/#"
                        onClick={function () {
                            client.signoutRedirect()
                        }}
                    >
                        Logout
                    </a>
                </div>
            </div>
        </div>
    )
})

export default UserDropdown
