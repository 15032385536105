export default [
    'ID',
    'Name',
    'API 14',
    'OperatorKey',
    'Operator Number',
    'API No',
    'Well Name',
    'Incident',
    'Postal Code',
    'Lease'
]