import React, { useCallback, useContext, useState } from 'react'
import { IconContext } from 'react-icons'
import { FaDownload, FaSpinner } from 'react-icons/fa'
import { Parser } from 'json2csv'
import { saveAs } from 'file-saver'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import { DefaultTooltip as Tooltip } from '../../Tooltip'
import exportableWidgetTypes from '../../../../utils/constants/widgets/exportableWidgetTypes'
import csvTransforms from '../../../../utils/constants/widgets/exportTransforms'
import { DateContext } from '../../../wrappers/DateContext'
import dayjs from 'dayjs'
import withConfig from '../../../wrappers/withConfig'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'

const constructDownloadName = (widget, date, exportPrefix) => {
    let downloadName = exportPrefix
        ? `${exportPrefix}_${widget.WidgetName}`
        : `${widget.PageName}_${widget.WidgetName}`
    if (!date.minDate) {
        const maxDateString = dayjs(date.maxDate).format('YYYYMMDD').toString()
        downloadName += `_Ending_${maxDateString}`
    } else {
        const minDateString = dayjs(date.minDate).format('YYYYMMDD').toString()
        const maxDateString = dayjs(date.maxDate).format('YYYYMMDD').toString()

        downloadName += `_${minDateString}-${maxDateString}`
    }
    const dateString = dayjs(Date.now()).format('YYYYMMDD')
    downloadName += `_Generated_${dateString}`
    return downloadName
}

export default withConfig(({ config }) => {
    const { widgetData: widget } = useContext(WidgetDataContext)
    const { detailKey } = useContext(CurrentDashboardContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { API_URL } = config
    const { date } = useContext(DateContext)
    const [exporting, setExporting] = useState(false)

    const fetchExportPrefix = useCallback(async () => {
        try {
            var url = widget.DetailKey
                ? `${API_URL}/widget/exportPrefix/${widget.WidgetId}?id=${detailKey}`
                : `${API_URL}/widget/exportPrefix/${widget.WidgetId}`
            return await authenticatedFetch(url)
                .then(async (response) => {
                    if (response.ok) {
                        return await response.text()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then((response) => {
                    return response
                })
        } catch (e) {
            return null
        }
    }, [widget, detailKey, API_URL])

    const downloadData = useCallback(async () => {
        if (!exporting) {
            setExporting(true)
            var exportPrefix = await fetchExportPrefix()
            try {
                const parser = new Parser({
                    transforms: csvTransforms
                })
                // convert the data to .csv
                let csv = parser.parse(widget.Data)

                // update the Value label field, if exists
                if (widget.ValueLabel) {
                    csv = csv.replace('"Value"', `"${widget.ValueLabel}"`)
                }
                const downloadName = `${constructDownloadName(
                    widget,
                    date,
                    exportPrefix
                )}.csv`
                saveAs(new Blob([csv], { type: 'text/csv' }), downloadName)
            } catch (e) {
                console.log('csv export error:', e)
            }
            setExporting(false)
        }
    }, [widget, date, exporting, fetchExportPrefix])

    // if there is no widget or no data, do not render this button
    if (!(widget && widget.Data && widget.Data.length)) {
        return null
    }

    // if the widget is not a supported exportable type,
    // then do not render this button
    if (!exportableWidgetTypes.includes(widget.WidgetType)) {
        return null
    }

    const tooltipId = `download-${widget.WidgetId}`
    return (
        <div
            className="exportButton"
            data-tip={'Download Data'}
            data-for={tooltipId}
        >
            {!exporting ? (
                <div
                    className="exportButtonIcon"
                    onClick={() => downloadData()}
                >
                    <FaDownload />
                </div>
            ) : (
                <div className="exportButtonIcon">
                    <IconContext.Provider value={{ className: 'fa-pulse' }}>
                        <FaSpinner />
                    </IconContext.Provider>
                </div>
            )}
            <Tooltip id={tooltipId} place={'bottom'} />
        </div>
    )
})
